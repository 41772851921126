import { createStore } from 'vuex'
import axios from 'axios';
import { router } from '../router'
import {
    CLIENTS_ADD,
    CLIENTS_EDIT,
    CLIENTS_REQUEST,
    PUBLISH_MESSAGE,
    USER_LOGIN,
    USER_LOGIN_ERROR,
    USER_LOGOUT,
    RULES_ADD,
    RULES_EDIT,
    RULES_DELETE,
    RELAY_CONFIG_UPDATE
} from './mutation-types'
import WebSocketManager from '../services/WebSocketManager';

// Create a new store instance.
const store = createStore({
    state () {
        return {
            lastLoginError: '',
            clients: [],
            loggedIn: localStorage.getItem('csrf_token'),
            clientStateChanges: {} // Track last state change time per client
        }
    },
    mutations: {
        [CLIENTS_ADD] (state, client) {
            state.clients.push(client);
        },
        [CLIENTS_EDIT] (state, client) {
            const editedClient = state.clients.find(c => c.id === client.id);
            editedClient.name = client.name;
        },
        [CLIENTS_REQUEST] (state, clients) {
            state.clients = clients;
        },
        [PUBLISH_MESSAGE] () {
        },
        [USER_LOGIN] (state, loginResponse) {
            state.loggedIn = true;
            state.lastLoginError = '';
            localStorage.setItem('csrf_token', loginResponse.csrf_token);
            WebSocketManager.connect();
            router.push("/");
        },
        [USER_LOGIN_ERROR] (state, errorStatus) {
            state.lastLoginError = errorStatus == 403
                ? 'Wrong username or password'
                : 'Error on login request: ' + errorStatus;
        },
        [USER_LOGOUT] (state) {
            state.clients = [];
            state.loggedIn = false;
            state.clientStateChanges = {};
            localStorage.removeItem('csrf_token');
            WebSocketManager.disconnect();
            router.push("/");
        },
        [RULES_ADD] (state, payload) {
			const clientIndex = state.clients.findIndex(c => c.id === payload.clientId);
			if (clientIndex !== -1) {
				const client = state.clients[clientIndex];
				state.clients[clientIndex] = {
					...client,
					rules: payload.rules
				};
			}
        },
        [RULES_EDIT] (state, payload) {
            const editedClient = state.clients.find(c => c.id === payload.clientId);
            const editedRulePos = editedClient.rules.findIndex(r => r.id === payload.ruleId);

            for (const key in payload.rule) {
                editedClient.rules[editedRulePos][key] = payload.rule[key];
            }
        },
        [RULES_DELETE] (state, payload) {
            const client = state.clients.find(c => c.id === payload.clientId);
            const rule = client.rules.findIndex(r => r.time === payload.rule.time);
            client.rules.splice(rule, 1);
        },
        [RELAY_CONFIG_UPDATE] (state, payload) {
            const client = state.clients.find(c => c.id === payload.clientId);
            client.normallyClosedRelay = payload.normallyClosedRelay;
        },
        updateClientState(state, { clientId }) {
            state.clientStateChanges[clientId] = Date.now();
        }
    },
    actions: {
        [USER_LOGIN] ({ commit }, { username, password}) {
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('password', password);

            return axios
                .post(
                    process.env.VUE_APP_ROOT_API + 'login',
                    params,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    }
                )
                .then(
                    response => commit(USER_LOGIN, response.data.data),
                    reason => {
                        if (reason.response) {
                            commit(USER_LOGIN_ERROR, reason.response.status);
                        }
                    }
                );
        },
        [USER_LOGOUT] ({ commit }) {
            localStorage.removeItem('user');
            commit(USER_LOGOUT);
        },
        [CLIENTS_ADD] ({ commit }, { name }) {
            const params = new URLSearchParams();
            params.append('name', name);

            return axios
                .post(
                    process.env.VUE_APP_ROOT_API + 'clients',
                    params,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        }
                    }
                ).
                then(
                    response => commit(CLIENTS_ADD, response.data.data.client),
                    reason => {
                        if (reason.response) {
                            console.error(reason.response);
                            alert(reason.response.statusText);
                        }
                    }
                );
        },
        [CLIENTS_EDIT] ({ commit }, { id, name }) {
            const params = new URLSearchParams();
            params.append('name', name);

            return axios
                .put(
                    process.env.VUE_APP_ROOT_API + 'clients/' + id,
                    params,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        }
                    }
                ).
                then(
                    response => commit(CLIENTS_EDIT, response.data.data.client),
                    reason => {
                        if (reason.response) {
                            console.error(reason.response);
                            alert(reason.response.statusText);
                        }
                    }
                );
        },
        [CLIENTS_REQUEST] ({ commit, state }) {
            if (state.clients.length > 0) {
                return
            }

            return axios
                .get(process.env.VUE_APP_ROOT_API + 'clients', {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                    }
                })
                .then(
                    response => commit(CLIENTS_REQUEST, response.data.data.clients),
                    reason => {
                        if (reason.response.status != 400) {
                            console.error(reason.response);
                        }

                        router.push("/logout");
                    }
                )
        },
        [PUBLISH_MESSAGE] ({ commit }, { id, payload }) {
            return axios
                .post(
                    process.env.VUE_APP_ROOT_API + 'clients/' + id + '/messages',
                    payload,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        }
                    }
                )
                .then(
                    response => commit(PUBLISH_MESSAGE, response)
                );
        },
        [RULES_ADD] ({ commit }, { clientId, rule }) {
            const params = new URLSearchParams();
            params.append('payload', rule.payload);
            params.append('frequency', rule.frequency);
            params.append('time', rule.time);
            params.append('timerMinutes', rule.timerMinutes);
            params.append('timezone', 'UTC');

            return axios
                .post(
                    process.env.VUE_APP_ROOT_API + 'clients/' + clientId + '/rules',
                    params,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        }
                    }
                )
            .then(
                response => commit(RULES_ADD, { clientId, rules: response.data.data.rules }),
                reason => {
                    if (reason.response) {
                        console.error(reason.response);
                        alert(reason.response.statusText);
                    }
                }
            );
        },
        [RULES_EDIT] ({ commit }, { clientId, rule }) {
            const params = new URLSearchParams();
            params.append('payload', rule.payload);
            params.append('frequency', rule.frequency);
            params.append('time', rule.time);
            params.append('timerMinutes', rule.timerMinutes);
            params.append('timezone', 'UTC');

            return axios
                .put(
                    process.env.VUE_APP_ROOT_API + 'clients/' + clientId + '/rules/' + rule.id,
                    params,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        }
                    }
                )
            .then(
                response => commit(RULES_EDIT, { clientId, ruleId: rule.id, rule: response.data.data.rule }),
                reason => {
                    if (reason.response) {
                        console.error(reason.response);
                        alert(reason.response.statusText);
                    }
                }
            );
        },
        [RULES_DELETE] ({ commit }, { clientId, rule }) {
            return axios
                .delete(
                    process.env.VUE_APP_ROOT_API + 'clients/' + clientId + '/rules/' + rule.time.split(':').join('/'),
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        }
                    }
                )
            .then(
                response => commit(RULES_DELETE, { clientId, rule, response }),
                reason => {
                    if (reason.response) {
                        console.error(reason.response);
                        alert(reason.response.statusText);
                    }
                }
            );
        },
        [RELAY_CONFIG_UPDATE] ({ commit }, { clientId, normallyClosedRelay }) {
            const params = new URLSearchParams();
            params.append('normallyClosedRelay', normallyClosedRelay);

            return axios
                .patch(
                    process.env.VUE_APP_ROOT_API + 'clients/' + clientId,
                    params,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('csrf_token')
                        },
                    }
                )
            .then(
                () => commit(RELAY_CONFIG_UPDATE, { clientId, normallyClosedRelay }),
                reason => {
                    if (reason.response) {
                        console.error(reason.response);
                        alert(reason.response.statusText);
                    }
                }
            );
        },
    }
})

export {
    store
}
