export function utcToLocalTime(time) {
    try {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setUTCHours(hours);
        date.setUTCMinutes(minutes);

        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    } catch (e) {
        console.error('Failed to convert UTC to local time:', e);
        return time;
    }
}

export function localToUtcTime(time) {
    try {
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        return `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`;
    } catch (e) {
        console.error('Failed to convert local to UTC time:', e);
        return time;
    }
}
