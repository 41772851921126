export const CLIENTS_ADD = 'CLIENTS_ADD';
export const CLIENTS_EDIT = 'CLIENTS_EDIT';
export const CLIENTS_REQUEST = 'CLIENTS_REQUEST';
export const PUBLISH_MESSAGE = 'PUBLISH_MESSAGE';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';

export const RULES_ADD = 'RULES_ADD';
export const RULES_EDIT = 'RULES_EDIT';
export const RULES_DELETE = 'RULES_DELETE';

export const RELAY_CONFIG_UPDATE = 'RELAY_CONFIG_UPDATE';
