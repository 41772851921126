<template>
    <div class="client">
        <div class="client-header">
            <div class="client-info">
                <span class="name" v-if="!editMode">{{ client.name }}</span>
                <input v-else type="text" v-model="newName"/>

                <span v-if="!client.connected" class="connection-status">OFFLINE</span>
                <span v-if="client.connected" class="connection-status connected">ONLINE</span>
            </div>
            
            <div class="device-config">
                <button 
                    @click="toggleConfigMode" 
                    class="config-button"
                    title="Device Configuration"
                >
                    <span class="material-icons-outlined">settings</span>
                </button>
            </div>
        </div>

        <div v-if="showConfig" class="config-panel">
            <div class="config-item">
                <label class="config-label">
                    <input 
                        type="checkbox" 
                        :checked="client.normallyClosedRelay"
                        @change="updateRelayConfig"
                    >
                    <span class="config-text">Normally Closed Relay</span>
                </label>
                <div class="config-description">
                    Enable this if the device uses a normally closed relay (inverted operation)
                </div>
            </div>
        </div>

        <div class="controls" :class="{ 'controls-disabled': controlsDisabled }">
            <div v-if="displayState === 'on'" class="turn-off-controls">
                <button 
                    @click="handleTurnOff()"
                    class="control-button turn-off"
                    :disabled="controlsDisabled"
                >
                    <span v-if="isLoading" class="loading-indicator"></span>
                    Turn Off
                </button>
            </div>

            <div v-else class="turn-on-controls">
                <div class="timer-presets">
                    <button 
                        v-for="preset in timePresets" 
                        :key="preset.value"
                        @click="handleTurnOn(preset.value)"
                        class="preset-button turn-on"
                        :disabled="controlsDisabled"
                    >
                        <span v-if="isLoading" class="loading-indicator"></span>
                        Turn on for {{ preset.label }}
                    </button>
                    <button 
                        @click="handleTurnOn(0)"
                        class="preset-button turn-on"
                        :disabled="controlsDisabled"
                    >
                        <span v-if="isLoading" class="loading-indicator"></span>
                        Turn on
                    </button>
                </div>
            </div>
        </div>

        <div v-if="client.status" class="status">
            <span class="item">
                Status:
                <span class="material-icons-outlined power-icon" :class="displayState">
                    power_settings_new
                </span>
                <span v-if="client.normallyClosedRelay" class="relay-type">
                    (Inverted)
                </span>
            </span>
            <button 
                @click="refreshState()"
                class="refresh-button"
                :disabled="controlsDisabled"
                title="Refresh state"
            >
                <span class="material-icons-outlined">refresh</span>
            </button>
        </div>

        <div class="rules">
            <div class="rules-header">
                <h4>Rules</h4>
                <button @click="addRule" class="add-rule-button">
                    <span class="material-icons-outlined">add</span>
                    Add Rule
                </button>
            </div>

            <div v-if="client.rules.length > 0" class="rules-list">
                <div v-for="rule in client.rules" :key="rule.id" class="rule-item">
                    <div class="rule-content">
                        <span class="rule-icon material-icons-outlined">
                            {{ formatRuleIcon(rule) }}
                        </span>
                        <div class="rule-details">
                            <div class="rule-primary">
                                {{ formatRuleFrequency(rule) }} at {{ formatTime(rule.time) }}
                            </div>
                            <div class="rule-secondary">
                                {{ formatRuleAction(rule) }}
                            </div>
                        </div>
                    </div>
                    <div class="rule-actions">
                        <button 
                            @click="editRule(rule)" 
                            class="icon-button"
                            title="Edit rule"
                        >
                            <span class="material-icons-outlined">edit</span>
                        </button>
                        <button 
                            @click="deleteRule(rule)" 
                            class="icon-button delete"
                            title="Delete rule"
                        >
                            <span class="material-icons-outlined">delete</span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="no-rules">
                No rules configured
            </div>

            <RuleDialog 
                :show="showRuleDialog"
                :editing-rule="editingRule"
                :is-inverted-device="client.normallyClosedRelay"
                @close="closeRuleDialog"
                @save="handleRuleSave"
            />
        </div>
    </div>
</template>

<style scoped>
.name {
    height: 2em;
    line-height: 2em;
}

.client-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.client-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.config-button {
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    color: #666;
}

.config-button:hover {
    background: #f0f0f0;
    color: #333;
}

.config-panel {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

.config-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.config-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.config-text {
    font-weight: 500;
}

.config-description {
    font-size: 0.9em;
    color: #666;
    margin-left: 24px;
}

.relay-type {
    font-size: 0.8em;
    color: #666;
    margin-left: 4px;
}

.connection-status {
    color: red;
    height: 2em;
    line-height: 2em;
    font-size: 0.8em;
}

.connection-status.connected {
    color: #006400;
}

.status {
    width: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.status .item {
    padding-right: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.status .power-icon {
    font-size: 1.4rem;
}

.status .power-icon.on {
    color: #4CAF50;
}

.status .power-icon.off {
    color: #dc3545;
}

.refresh-button {
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    margin-left: auto;
}

.refresh-button:hover {
    background: #f0f0f0;
}

.refresh-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.controls {
    margin: 10px 0;
    width: 100%;
}

.controls-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.timer-presets {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    width: 100%;
    max-width: 400px;
}

.preset-button {
    padding: 12px;
    border-radius: 8px;
    font-size: 1rem;
    touch-action: manipulation;
    cursor: pointer;
    width: 100%;
    position: relative;
}

.preset-button.turn-on {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.preset-button.turn-on:active {
    background-color: #3d8b40;
}

.turn-off {
    background-color: #dc3545;
    color: white;
    border: none;
}

.control-button {
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    touch-action: manipulation;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    position: relative;
}

.preset-button:disabled, 
.control-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Loading indicator styles */
.loading-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
}

.loading-indicator::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(0.6);
        opacity: 0.4;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.6);
        opacity: 0.4;
    }
}

/* Make buttons easier to tap on mobile */
@media (max-width: 768px) {
    .preset-button, .control-button {
        min-height: 48px;
    }
}

.rules {
    margin-top: 20px;
    width: 100%;
}

.rules-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.rules-header h4 {
    margin: 0;
}

.add-rule-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background: none;
    border: 1px solid #4CAF50;
    color: #4CAF50;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.add-rule-button:hover {
    background: #4CAF50;
    color: white;
}

.rules-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rule-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: #f5f5f5;
    border-radius: 8px;
    gap: 8px;
}

.rule-content {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

.rule-icon {
    color: #666;
}

.rule-details {
    flex: 1;
}

.rule-primary {
    font-weight: 500;
}

.rule-secondary {
    font-size: 0.9em;
    color: #666;
}

.rule-actions {
    display: flex;
    gap: 8px;
}

.icon-button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #666;
    border-radius: 4px;
}

.icon-button:hover {
    background: #eee;
}

.icon-button.delete:hover {
    color: #dc3545;
    background: #ffebee;
}

.no-rules {
    color: #666;
    font-style: italic;
    padding: 12px;
    text-align: center;
    background: #f5f5f5;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .config-panel {
        padding: 12px;
    }

    .rule-item {
        padding: 16px;
    }

    .icon-button {
        padding: 8px;
    }
}
</style>

<script>
import RuleDialog from './RuleDialog.vue';
import { utcToLocalTime } from '../utils/timeUtils';

export default {
    props: {
        client: {
            type: Object,
            required: true,
            validator: (obj) => {
                return typeof obj.normallyClosedRelay === 'boolean' || obj.normallyClosedRelay === undefined;
            }
        }
    },
    components: {
        RuleDialog
    },
    data() {
        return {
            editMode: false,
            showConfig: false,
            newName: this.client.name,
            timePresets: [
                { label: '15m', value: 15 },
                { label: '30m', value: 30 },
                { label: '1h', value: 60 },
            ],
            isLoading: false,
            loadingTimeout: null,
            showRuleDialog: false,
            editingRule: null
        }
    },
    computed: {
        deviceState() {
            let status = this.client.status;
            if (typeof status === 'string') {
                try {
                    status = JSON.parse(status);
                } catch (e) {
                    console.warn('Failed to parse client status:', e);
                    return 'off';
                }
            }
            return status?.state || status?.estado || 'off';
        },
        displayState() {
            return this.client.normallyClosedRelay ? 
                (this.deviceState === 'on' ? 'off' : 'on') : 
                this.deviceState;
        },
        controlsDisabled() {
            return !this.client.connected || this.isLoading;
        },
        lastStateChange() {
            return this.$store.state.clientStateChanges[this.client.id] || 0;
        }
    },
    watch: {
        lastStateChange: {
            handler(newVal) {
                if (newVal > 0) {
                    // Clear loading state when we detect a state change
                    this.isLoading = false;
                    if (this.loadingTimeout) {
                        clearTimeout(this.loadingTimeout);
                        this.loadingTimeout = null;
                    }
                }
            }
        }
    },
    methods: {
        toggleEditMode() {
            this.newName = this.client.name;
            this.editMode = !this.editMode;
        },
        toggleConfigMode() {
            this.showConfig = !this.showConfig;
        },
        save() {
            this.editMode = !this.editMode;
            if (this.newName !== this.client.name) {
                this.$emit('clientNameEdited', this.newName);
            }
        },
        startLoading() {
            this.isLoading = true;
            // Reset any existing timeout
            if (this.loadingTimeout) {
                clearTimeout(this.loadingTimeout);
            }
            // Set timeout to clear loading state after 2 minutes
            this.loadingTimeout = setTimeout(() => {
                this.isLoading = false;
            }, 120000);
        },
        updateRelayConfig(event) {
            this.$emit('relayConfigUpdated', {
                clientId: this.client.id,
                normallyClosedRelay: event.target.checked
            });
        },
        handleTurnOn(minutes) {
            this.startLoading();
            const command = this.client.normallyClosedRelay ? 'setState:off' : 'setState:on';
            this.publish(minutes, command);
        },
        handleTurnOff() {
            this.startLoading();
            const command = this.client.normallyClosedRelay ? 'setState:on' : 'setState:off';
            this.publish(0, command);
        },
        publish(timerMinutes, state) {
            let payload = {
                timerMinutes,
                payload: state
            };
            this.$emit('publishRequested', payload);
        },
        refreshState() {
            this.$emit('publishRequested', { payload: 'getState' });
        },
        addRule() {
            this.editingRule = null;
            this.showRuleDialog = true;
        },
        editRule(rule) {
            this.editingRule = rule;
            this.showRuleDialog = true;
        },
        closeRuleDialog() {
            this.showRuleDialog = false;
            this.editingRule = null;
        },
        async deleteRule(rule) {
            if (confirm('Are you sure you want to delete this rule?')) {
                this.$emit('ruleDeleted', {
                    clientId: this.client.id,
                    rule: rule
                });
            }
        },
        handleRuleSave(rule) {
            const [hours, minutes] = rule.time.split(':');
            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            const utcRule = {
                ...rule,
                time: `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`
            };

            if (this.editingRule) {
                this.$emit('ruleUpdated', {
                    clientId: this.client.id,
                    rule: utcRule
                });
            } else {
                this.$emit('ruleAdded', {
                    clientId: this.client.id,
                    rule: utcRule
                });
            }
            this.closeRuleDialog();
        },
        formatTime(time) {
			return utcToLocalTime(time);
        },
        formatRuleFrequency(rule) {
            if (rule.frequency === 'weekly') {
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                return `Every ${days[rule.dayOfWeek]}`;
            }
            return `Every ${rule.frequency.toLowerCase()}`;
        },
        formatRuleIcon(rule) {
            return this.relayOutputIsOn(rule) ? 'light_mode' : 'nights_stay'
        },
        formatRuleAction(rule) {
            if (this.client.normallyClosedRelay) {
                return (this.relayOutputIsOn(rule) ? 'Turn on' : `Turn off for ${rule.timerMinutes} minutes`) + ' (Inverted)';
            } else {
                return this.relayOutputIsOn(rule) ? `Turn on for ${rule.timerMinutes} minutes` : 'Turn off';
            }
        },
        relayOutputIsOn(rule) {
            return this.client.normallyClosedRelay ? rule.payload.includes(':off') : rule.payload.includes(':on');
        }
    },
    beforeUnmount() {
        // Clean up timeout if component is destroyed
        if (this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
        }
    }
};
</script>
