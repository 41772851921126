<template>
    <div class="rule-dialog-overlay" v-if="show" @click="closeDialog">
        <div class="rule-dialog" @click.stop>
            <div class="dialog-header">
                <h3>{{ isEditing ? 'Edit Rule' : 'Add New Rule' }}</h3>
                <button class="close-button" @click="closeDialog">
                    <span class="material-icons-outlined">close</span>
                </button>
            </div>

            <form @submit.prevent="saveRule">
                <div class="form-group">
                    <label>Frequency</label>
                    <select v-model="ruleData.frequency" class="form-control">
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="weekdays">Weekdays</option>
                        <option value="weekends">Weekends</option>
                    </select>
                </div>

                <div v-if="ruleData.frequency === 'weekly'" class="form-group">
                    <label>Day of Week</label>
                    <select v-model="ruleData.dayOfWeek" class="form-control">
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                        <option value="0">Sunday</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Time</label>
                    <input type="time" v-model="ruleData.time" class="form-control" required>
                </div>

                <div class="form-group">
                    <label>Action</label>
                    <select v-model="ruleData.displayAction" class="form-control">
                        <option value="on">Turn On</option>
                        <option value="off">Turn Off</option>
                    </select>
                    <div v-if="isInvertedDevice" class="inverted-note">
                        Device uses inverted relay - commands will be reversed
                    </div>
                </div>

                <div class="form-group" v-if="actualAction === 'on'">
                    <label>Duration (minutes)</label>
                    <input 
                        type="number" 
                        v-model.number="ruleData.timerMinutes" 
                        class="form-control"
                        min="0"
                        max="1440"
                        required
                    >
                </div>

                <div class="dialog-buttons">
                    <button type="button" class="btn-secondary" @click="closeDialog">Cancel</button>
                    <button type="submit" class="btn-primary">
                        {{ isEditing ? 'Update Rule' : 'Add Rule' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { localToUtcTime, utcToLocalTime } from '../utils/timeUtils';

export default {
    name: 'RuleDialog',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        editingRule: {
            type: Object,
            default: null
        },
        isInvertedDevice: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            ruleData: this.getInitialRuleData()
        }
    },
    computed: {
        isEditing() {
            return !!this.editingRule;
        },
        actualAction() {
            // Convert display action to actual relay action based on device type
            return this.isInvertedDevice ? 
                (this.ruleData.displayAction === 'on' ? 'off' : 'on') : 
                this.ruleData.displayAction;
        }
    },
    methods: {
        getInitialRuleData() {
            return this.parseExistingRule({});
        },
        parseExistingRule(rule) {
            // Determine the display action based on payload and device type
            const actualAction = rule.payload?.includes(':on') ? 'on' : 'off';
            const displayAction = this.isInvertedDevice ? 
                (actualAction === 'on' ? 'off' : 'on') : 
                actualAction;

            return {
                frequency: rule.frequency || 'daily',
                dayOfWeek: (rule.dayOfWeek ?? 1).toString(),
                time: rule.time ? utcToLocalTime(rule.time) : '',
                displayAction: displayAction,
                actualAction: actualAction,
                timerMinutes: rule.timerMinutes || 0
            };
        },
        closeDialog() {
            this.$emit('close');
        },
        saveRule() {
            const payload = {
                frequency: this.ruleData.frequency,
                time: localToUtcTime(this.ruleData.time),
                payload: `setState:${this.actualAction}`,
                timerMinutes: this.actualAction === 'on' ? this.ruleData.timerMinutes : 0
            };

            if (this.ruleData.frequency === 'weekly') {
                payload.dayOfWeek = parseInt(this.ruleData.dayOfWeek);
            }

            // If editing, preserve the original rule ID
            if (this.isEditing) {
                payload.id = this.editingRule.id;
            }

            this.$emit('save', payload);
            this.closeDialog();
        }
    },
    watch: {
        editingRule: {
            immediate: true,
            handler(rule) {
                this.ruleData = rule ? this.parseExistingRule(rule) : this.getInitialRuleData();
            }
        }
    }
}
</script>

<style scoped>
.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dialog-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #666;
    border-radius: 4px;
}

.close-button:hover {
    background: #f0f0f0;
}

.rule-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rule-dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.btn-primary {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.inverted-note {
    font-size: 0.8em;
    color: #666;
    margin-top: 4px;
    padding: 4px 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    border-left: 3px solid #ffc107;
}

@media (max-width: 768px) {
    .form-control, .btn-primary, .btn-secondary {
        min-height: 44px;
    }
}
</style>
